































































import Vue from "vue";
import Component from "vue-class-component";
import moment, { Moment } from "moment";
import taskModule from "@/store/modules/tasks";
import subjectModule from "@/store/modules/subjects";
import EventTask from "@/types/eventTask";
import { TaskType } from "@/types/helpers";
import GroupStat from "@/types/GroupStat";
import CalendarDayLabel from "@/components/calendar/CalendarDayLabel.vue";

@Component({
  components: {
    CalendarDayLabel,
  },
})
export default class GroupCalendar extends Vue {
  focus = moment().calendar();
  weekdays = [1, 2, 3, 4, 5, 6, 0];
  type = "month";
  typeToLabel = {
    month: "Month",
    week: "Week",
    day: "Day",
  };

  get date(): Moment {
    return moment(`${this.focus}T00:00:00`);
  }

  get stats(): GroupStat[] | undefined {
    return taskModule.stats;
  }

  get isTasksLoaded(): boolean {
    return taskModule.status == "loaded";
  }

  get events(): EventTask[] {
    let events: EventTask[] = [];

    taskModule.tasks.forEach((item) =>
      events.push({
        name: item.title,
        start:
          item.tasktype_id == TaskType.PROJECT.toString()
            ? moment(item.start_at).toDate()
            : moment(item.due_at).toDate(),
        end: moment(item.due_at).toDate(),
        color: subjectModule.getSubject(item.subject_id)?.color,
        timed: false,
        id: item.id,
      })
    );

    return events;
  }

  viewDay({ date }: { date: string }): void {
    this.focus = date;
    this.type = "day";
  }

  showEvent({ event }: { event: EventTask }): void {
    this.$router.push({ name: "taskDisplay", params: { task_id: event.id } });
  }

  mounted(): void {
    // force update as title calendar doesnt appear otherwise
    this.$forceUpdate();
  }
}
